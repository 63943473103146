import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import Link from "components/transition-link"
import Overline from "components/overline"
import { renderImage } from "utils/imageRender"

const PageNotFound = ({ data: { wp } }) => {
  const { overline, heading, link, image } = wp.options.options.pageNotFound

  return (
    <Transition>
      <SEO title={`404 — Together`} />
      <section className="mt-32 sm:mt-40 md:mt-48 text-center o-container">
        <div className="max-w-xl mx-auto mb-20 style-links">
          <Overline>{overline}</Overline>
          <div className="mb-8 font-serif text-72px">{heading}</div>
          {link && (
            <Link className="text-midnight" to={link.url}>
              {link.title}
            </Link>
          )}
        </div>
        <div className="max-w-5xl sm:px-12 mx-auto mb-36">
          {renderImage(image, "w-full")}
        </div>
      </section>
    </Transition>
  )
}

export default PageNotFound

export const pageQuery = graphql`
  query PageNotFound {
    wp {
      options {
        options {
          pageNotFound {
            overline
            heading
            link {
              url
              title
            }
            image {
              ...FluidFullWidthImage
            }
          }
        }
      }
    }
  }
`
